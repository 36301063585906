import {
  ConfigurableProductDetailsFragment,
  GetProductsByUrlKeyQuery,
} from '../../../../graphql/magento'
import {
  AttributeName,
  AttributeSkuMapping,
  AttributeValue,
  FormattedVariantData,
  formattedVariantData,
} from '../../../../utils/itemTools'

export type Item = NonNullable<NonNullable<GetProductsByUrlKeyQuery['products']>['items']>[number]

// taken from www/src/components/shop/pdp/ItemContainer.tsx/takeIfConfigurableProduct
export const takeIfConfigurableProduct = (
  item: Item | undefined
): ConfigurableProductDetailsFragment | undefined =>
  item?.__typename === 'ConfigurableProduct' ? item : undefined

export type VariantFieldValues = Record<AttributeName, AttributeValue>
export const variantDataFromItem = (
  item: ConfigurableProductDetailsFragment
): { defaultChildSku: string; variantData: FormattedVariantData } => {
  const variantData = formattedVariantData({ variant_index: item.variant_index || {} })
  const { usedAttributes } = variantData

  const defaultChildSku = (usedAttributes.reduce(
    (check, att) => check[variantData.attributeValues[att][0]] as AttributeSkuMapping,
    variantData.mapping
  ) as unknown) as string

  return { defaultChildSku, variantData }
}

export const defaultItemFormValues = ({
  availableVariantSkus,
  usedAttributes,
  availableVariantOptions,
  attributeValues,
  productIndex,
  sku,
}: FormattedVariantData & { sku?: string } & {
  availableVariantOptions: Record<AttributeName, AttributeValue[]> | null
}): Record<AttributeName, AttributeValue> => {
  if (availableVariantSkus.length === 1) {
    return usedAttributes.reduce(
      (collection, attribute) => ({
        ...collection,
        [attribute]: availableVariantOptions?.[attribute][0],
      }),
      {}
    )
  }

  if (sku && availableVariantSkus.includes(sku)) {
    // if the sku is in the list of available skus, then we need to find the attribute values that match the sku
    return usedAttributes.reduce(
      (collection, attribute) => ({
        ...collection,
        [attribute]: productIndex[sku][attribute],
      }),
      {}
    )
  }

  // Select the first option of each attribute as default
  return usedAttributes.reduce(
    (collection, attribute) => ({
      ...collection,
      [attribute]: attributeValues[attribute][0],
    }),
    {}
  )
}

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Controller, FieldErrors, UseControllerOptions, useFormContext } from 'react-hook-form'
import { formFieldLabelText } from '../../lib/cart/utils'

export interface CheckboxProps
  extends Omit<
    MuiCheckboxProps,
    'checked' | 'defaultChecked' | 'defaultValue' | 'inputRef' | 'onBlur' | 'onChange' | 'value'
  > {
  defaultValue?: boolean
  errors?: FieldErrors
  label: ReactNode | string
  name: string
  rules?: UseControllerOptions['rules']
}

export const Checkbox: React.FC<CheckboxProps> = ({
  'aria-label': ariaLabel,
  className = '',
  defaultValue = false,
  disabled = false,
  id,
  label,
  name,
  rules,
  ...other
}) => {
  const { control, errors } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onBlur, onChange, ref, value }) => (
        <FormControlLabel
          control={
            <MuiCheckbox
              className={className}
              disabled={disabled}
              inputProps={{ 'aria-label': ariaLabel || (typeof label === 'string' ? label : '') }}
              {...other}
              checked={value}
              inputRef={ref}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          id={id || name}
          label={formFieldLabelText({ error: errors[name], label })}
          labelPlacement="end"
          name={name}
        />
      )}
      rules={rules}
    />
  )
}

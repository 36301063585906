import { useEffect, useMemo } from 'react'
import { isLeftWithError, logAndCaptureException, ResultError } from '../utils/errorTools'

export interface LeftEffect<R extends { _tag: 'Left' } | { _tag: 'Right' }> {
  (left: Extract<R, { _tag: 'Left' }>): void
}

const logAndCaptureLeft: LeftEffect<{ _tag: 'Left' } | { _tag: 'Right' }> = (result) => {
  logAndCaptureException(isLeftWithError(result) ? result.error : new ResultError(result))
}

export const useResultWithLeftEffect = <R extends { _tag: 'Left' } | { _tag: 'Right' }, T1, T2>(
  result: R,
  project: (value: Extract<R, { _tag: 'Right' }>) => T1,
  defaultIfLeft: () => T2,
  leftEffect: LeftEffect<R> = logAndCaptureLeft
): [Extract<R, { _tag: 'Left' }> | undefined, T1 | T2] => {
  useEffect(() => {
    if (result._tag !== 'Left') {
      return
    }
    leftEffect(result as Extract<R, { _tag: 'Left' }>)
  }, [leftEffect, result])

  return useMemo(
    () =>
      result._tag === 'Right'
        ? [undefined, project(result as Extract<R, { _tag: 'Right' }>)]
        : [result as Extract<R, { _tag: 'Left' }>, defaultIfLeft()],
    [defaultIfLeft, project, result]
  )
}

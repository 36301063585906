import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import withDispensaryAccount from '../../../hoc/withDispensaryAccount'
import { Dispensary, getDispensaryBasePath } from '../../../lib/dispensaries'
import { generateEScriptLink } from '../../../lib/escript/generator'
import { PractitionerSettings } from '../../../lib/practitioner-settings/types'
import ClickToCopy from '../../global/ClickToCopy'
import Spinner from '../../Spinner'
import DispensaryPromoSelector from './DispensaryPromoSelector'
import { ProductList } from './ProductList'
import { ProductModal } from './ProductModal'
import { openModalForProduct } from './ProductModal/useModalState'
import * as Styled from './styled'
import {
  actions,
  Prescription,
  usePrescriptionBuilder,
  UsePrescriptionBuilderDispatch,
} from './usePrescriptionBuilder'

const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const formatDate = (): string => {
  const date = new Date()
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return year + month + day
}

const generateEScriptLinkAdapter = (dispensaryUrl: string, prescription: Prescription) => {
  if (typeof window === 'undefined') return ''
  const h = generateRandomString(8).concat(formatDate())
  return generateEScriptLink({
    dispensaryUrl,
    products: prescription.products.map((product) => ({
      sku: product.sku,
      quantity: product.quantity,
      autoShip: product.autoShip ? `${product.autoShip}m` : null,
    })),
    version: '',
    promoCode: prescription.promoCode || null,
    eScriptCampaign: 'bldr_unlabeled',
    h,
  })
}

const onRemoveItem = (dispatch: UsePrescriptionBuilderDispatch) => (sku: string) => () =>
  dispatch(actions.remove(sku))

const onSelectPromoCode = (dispatch: UsePrescriptionBuilderDispatch) => (code: string) =>
  dispatch(actions.setPromoCode(code))

const Builder: React.FC<{
  settings: PractitionerSettings
  dispensary: Dispensary
}> = ({ dispensary }) => {
  const location = useLocation()
  const dispensaryLink = useMemo(
    () =>
      `${location?.origin ?? 'https://www.designsforhealth.com'}${getDispensaryBasePath(
        dispensary.slug
      )}`,
    [location, dispensary.slug]
  )

  const { prescription, dispatch, loading } = usePrescriptionBuilder()

  const [eScriptLink, setEScriptLink] = useState(
    generateEScriptLinkAdapter(dispensaryLink, prescription)
  )

  // Update the eScript link when the prescription changes
  useEffect(() => {
    setEScriptLink(generateEScriptLinkAdapter(dispensaryLink, prescription))
  }, [dispensaryLink, prescription])

  const products = useMemo(() => prescription.products, [prescription.products])
  const promoCode = useMemo(() => prescription.promoCode, [prescription.promoCode])

  if (loading) {
    return (
      <Styled.Wrapper>
        <Styled.Content>
          <Spinner loading />
        </Styled.Content>
      </Styled.Wrapper>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Heading>eScript Builder</Styled.Heading>
        <Styled.SubHeading>How to Use</Styled.SubHeading>
        <Styled.Instructions>
          To add products to this eScript you can either use the search bar located below, or click
          the &quot;Add to eScript&quot; button on the product page of your choice.
        </Styled.Instructions>
        <Styled.ProductListSearch
          onSelect={(product) =>
            openModalForProduct({ urlKey: product.urlKey, sku: product.sku, mode: 'add' })
          }
        />
        <ProductModal />
        {products.length > 0 && (
          <>
            <Styled.ItemsContainer>
              <Styled.SubHeading>Share this link with your patients</Styled.SubHeading>
              <ClickToCopy copyText={eScriptLink} />
              <Styled.SubHeading>Products in this eScript</Styled.SubHeading>
              <ProductList products={products} onRemoveItem={onRemoveItem(dispatch)} />
            </Styled.ItemsContainer>
            <DispensaryPromoSelector
              promoCode={promoCode}
              onSelectPromoCode={onSelectPromoCode(dispatch)}
            />
          </>
        )}
      </Styled.Content>
    </Styled.Wrapper>
  )
}

const StyledSpinner = styled(Spinner)`
  // make the loading component be taller, so that the footer is not in the middle of the screen
  height: 70vh;
`

export default withAuthenticationRequired(
  withDispensaryAccount(Builder, {
    onLoading: () => <StyledSpinner loading />,
  }),
  {
    onRedirecting: () => <StyledSpinner loading />,
  }
)

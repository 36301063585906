import { ButtonLink, PrimaryButton } from '@designsforhealth/dfh-react-components'
import { Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

import ItemHeading from '../../../shop/pdp/item_details/ItemHeading'

export const ModalContainer = styled.div`
  position: relative;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 90%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 767px) {
    padding: 10px;
    max-height: 85vh;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
`

export const DetailsGrid = styled(Grid)`
  @media (min-width: 600px) {
    padding-left: 15px !important;
  }

  @media (max-width: 600px) {
    padding-top: 10px !important;
  }
`

export const ModalSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  padding: 20px;
`

export const ModalErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
`

export const CloseButton = styled(ButtonLink)`
  position: absolute;
  top: 7px;
  right: 20px;
  flex: 0 0 44px;
  height: 44px;
  margin-right: -15px;
  padding: 15px;
  background-color: transparent;
`

export const Heading = styled(ItemHeading)`
  .sku {
    margin-bottom: 8px;
  }
`

export const DisabledPrescribeButton = styled(PrimaryButton)(
  ({ theme }) => css`
    background: ${theme.colors.grayscale.gray20};
  `
)

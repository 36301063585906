import { ApolloQueryResult } from '@apollo/client'
import { NetworkStatus } from '@apollo/client/core/networkStatus'
import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { combineLatest, filter, from, map, Observable, takeUntil, tap, withLatestFrom } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import {
  ConfigurableProductDetailsFragment,
  GetProductsByUrlKeyDocument,
  GetProductsByUrlKeyQuery,
} from '../../../../graphql/magento'
import { latestAccessToken$ } from '../../../../lib/auth/state'
import { latestApolloClient$ } from '../../../../lib/graphql/apollo'
import { isNotNull } from '../../../../utils/collectionTools'
import { FormattedVariantData } from '../../../../utils/itemTools'
import { takeIfConfigurableProduct, variantDataFromItem } from './helpers'

type ModalState = ModalClosed | ModalLoading | ModalOpen | ModalError
type ModalClosed = { type: 'CLOSED' }
type ModalLoading = { type: 'LOADING' }
type ModalError = { type: 'ERROR'; error?: Error }
type ModalOpen = { type: 'OPEN'; data: InitialModalData }
export type InitialModalData = {
  item: ConfigurableProductDetailsFragment
  variantData: FormattedVariantData
  defaultChildSku: string
  openingSku: string // the sku of the product that opened the modal (can be parent or child)
  openingUrlKey: string // the urlKey of the product that opened the modal (can be parent or child)
  openingMode: 'edit' | 'add'
}

const modalState = {
  closed: (): ModalState => ({ type: 'CLOSED' }),
  loading: (): ModalState => ({ type: 'LOADING' }),
  error: (error?: Error): ModalState => ({ type: 'ERROR', error }),
  open: (initialData: InitialModalData): ModalState => ({ type: 'OPEN', data: initialData }),
}

const [closeModal$, onCloseModal] = createSignal<void>()

export type ModalProduct = { urlKey: string; sku: string; mode: 'edit' | 'add' }
const [openModalForProduct$, openModalForProduct] = createSignal<ModalProduct>()

const latestApolloClientAndToken$ = combineLatest([
  latestApolloClient$,
  latestAccessToken$.pipe(filter(isNotNull)),
]).pipe(map(([client, token]) => ({ client, token })))

const productsByUrlKey$: Observable<ApolloQueryResult<GetProductsByUrlKeyQuery>> = combineLatest([
  openModalForProduct$,
  latestApolloClientAndToken$,
]).pipe(
  switchMap(([modalProduct, { client, token }]) =>
    from(
      client.query({
        query: GetProductsByUrlKeyDocument,
        variables: { urlKey: modalProduct.urlKey },
        context: { headers: { authorization: `Bearer ${token}` } },
        fetchPolicy: 'cache-first',
      })
    ).pipe(takeUntil(closeModal$))
  )
)

const configurableProductOrNull$ = productsByUrlKey$.pipe(
  filter(({ networkStatus }) => networkStatus === NetworkStatus.ready),
  map(({ data }) => takeIfConfigurableProduct(data?.products?.items?.[0] ?? null) ?? null)
)

const [modalState$, setModalState] = createSignal<ModalState>()
const [useModalState, latestModalState$] = bind<ModalState>(modalState$, modalState.closed())

// when a product is selected set the modal state to loading
openModalForProduct$.subscribe(() => setModalState(modalState.loading()))

// when an item finishes loading set the modal state to
// open with the item data if it is a configurable product
// or error if it is not
configurableProductOrNull$
  .pipe(
    tap((item) => !item && setModalState(modalState.error(new Error('Product not found')))),
    filter(isNotNull),
    withLatestFrom(openModalForProduct$)
  )
  .subscribe(([item, product]) =>
    setModalState(
      modalState.open({
        item,
        ...variantDataFromItem(item),
        openingSku: product.sku,
        openingUrlKey: product.urlKey,
        openingMode: product.mode,
      })
    )
  )

// when an error occurs while fetching a product set the modal state to error
productsByUrlKey$
  .pipe(filter(({ networkStatus }) => networkStatus === NetworkStatus.error))
  .subscribe(({ error }) => setModalState(modalState.error(error)))

closeModal$.subscribe(() => setModalState(modalState.closed()))

const closeModal = () => onCloseModal()

export { closeModal, latestModalState$, openModalForProduct, useModalState }

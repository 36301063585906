import { useAuth0 } from '@auth0/auth0-react'
import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import { useLocation } from '@gatsbyjs/reach-router'
import { Grid } from '@material-ui/core'
import { Subscribe } from '@react-rxjs/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from 'styled-react-modal'

import { SimpleProductDetailsFragment } from '../../../../graphql/magento'
import useIsSsr from '../../../../hooks/useIsSsr'
import useUserAccessPolicy from '../../../../hooks/useUserAccessPolicy'
import useUserCategory from '../../../../hooks/useUserCategory'
import { usePageUrl } from '../../../../lib/pages'
import { useTestKitProducts } from '../../../../lib/products/hooks/useTestKitProducts'
import { userCategories } from '../../../../utils/userClaims'
import ItemImage from '../../../shop/pdp/item_images/ItemImage'
import { TestKitLegalTerms } from '../../../shop/pdp/TestKitLegalTerms'
import Spinner from '../../../Spinner'
import AutoShipSelector from '../AutoShipSelector'
import { ItemVariantOptions } from '../ItemVariantOptions'
import QuantitySelector from '../QuantitySelector'
import { actions, AutoShipOption, usePrescriptionBuilder } from '../usePrescriptionBuilder'
import * as Styled from './styled'
import { closeModal, InitialModalData, latestModalState$, useModalState } from './useModalState'

import closeModalIcon from '../../../../img/close_modal.svg'

const ModalContent: React.VFC<{
  onCloseModal: () => void
  initialModalData: InitialModalData
}> = ({ onCloseModal, initialModalData }) => {
  const { prescription, dispatch } = usePrescriptionBuilder()
  const {
    openingSku,
    openingUrlKey,
    openingMode,
    item,
    variantData,
    defaultChildSku,
  } = initialModalData

  const prescriptionAutoShip = useMemo(
    () => prescription.products.find((p) => p.sku === openingSku)?.autoShip ?? null,
    [openingSku, prescription.products]
  )
  const prescriptionQuantity = useMemo(
    () => prescription.products.find((p) => p.sku === openingSku)?.quantity ?? 1,
    [openingSku, prescription.products]
  )

  const [autoShip, setAutoShip] = useState<AutoShipOption>(prescriptionAutoShip)
  const [quantity, setQuantity] = useState<number>(prescriptionQuantity)
  const [selectedVariantSku, setSelectedVariantSku] = useState<string>(
    openingMode === 'edit' ? openingSku : defaultChildSku
  )

  const selectedVariant = useMemo<SimpleProductDetailsFragment | null>(
    () => item?.variants?.find((ver) => ver?.product?.sku === selectedVariantSku)?.product ?? null,
    [item, selectedVariantSku]
  )

  const selectedVariantImage = useMemo(() => selectedVariant?.image ?? item.image, [
    selectedVariant,
    item,
  ])

  const hasAnythingChanged = useMemo(
    () =>
      autoShip !== prescriptionAutoShip ||
      quantity !== prescriptionQuantity ||
      selectedVariantSku !== openingSku,
    [autoShip, openingSku, prescriptionAutoShip, prescriptionQuantity, quantity, selectedVariantSku]
  )

  const isTestKit = useTestKitProducts().isTestKitSku(selectedVariant?.sku || '')
  const { isAuthenticated } = useAuth0()
  const isPatient = useUserCategory().userCategory === userCategories.patient
  const { canPurchaseTestKits } = useUserAccessPolicy()

  const showTestKitLegalTerms =
    openingMode === 'add' && isTestKit && isAuthenticated && !isPatient && canPurchaseTestKits

  // use form to validate required terms (if any)
  const formMethods = useForm()
  const { trigger } = formMethods

  const onSubmit = useCallback(() => {
    hasAnythingChanged &&
      dispatch(
        openingMode === 'edit'
          ? actions.updateProduct(openingSku, {
              sku: selectedVariantSku,
              quantity,
              autoShip,
              urlKey: openingUrlKey,
            })
          : actions.add({
              sku: selectedVariantSku,
              quantity,
              autoShip,
              urlKey: openingUrlKey,
            })
      )

    onCloseModal()
  }, [
    autoShip,
    dispatch,
    hasAnythingChanged,
    onCloseModal,
    openingMode,
    openingSku,
    openingUrlKey,
    quantity,
    selectedVariantSku,
  ])

  return (
    <>
      <Styled.CloseButton onClick={onCloseModal} aria-label="Close modal">
        <img alt="Close modal" src={closeModalIcon} />
      </Styled.CloseButton>
      <Grid container spacing={2}>
        {selectedVariantImage ? (
          <Grid item xs={12} sm={6}>
            <ItemImage image={selectedVariantImage} showItemImagePreviews={false} />
          </Grid>
        ) : null}
        <Styled.DetailsGrid item xs={12} sm={6}>
          <Styled.Heading
            item={{ sku: selectedVariantSku, name: item.name }}
            selectedVariant={null} // if we were to use selectedVariant instead of null
            // it would show the price of the selected variant for practitioners, and
            // we don't want that. If price is needed later we would want to show the price that patients see
          />
          <ItemVariantOptions
            variantData={variantData}
            onVariantSelected={setSelectedVariantSku}
            selectedVariantSku={selectedVariantSku}
          />
          <QuantitySelector quantity={quantity} onUpdateQuantity={setQuantity} />
          {!isTestKit && <AutoShipSelector autoShip={autoShip} onUpdateAutoShip={setAutoShip} />}
          {showTestKitLegalTerms && (
            // provide form context for TestKitLegalTerms
            <FormProvider {...formMethods}>
              <TestKitLegalTerms isDisabled={false} />
            </FormProvider>
          )}
          {openingMode === 'add' ? (
            <PrimaryButton
              onClick={(e) => {
                e.preventDefault()
                trigger()
                  .then((isValid) => isValid && onSubmit())
                  .catch(() => {
                    // noop
                  })
              }}
            >
              Add to eScript
            </PrimaryButton>
          ) : (
            <PrimaryButton disabled={!hasAnythingChanged} onClick={onSubmit}>
              Save Changes
            </PrimaryButton>
          )}
        </Styled.DetailsGrid>
      </Grid>
    </>
  )
}

const editHash = '#edit'

export const ProductModalReactive: React.VFC = () => {
  const state = useModalState()
  const isSsr = useIsSsr()
  const location = useLocation()
  const { navigate } = usePageUrl()

  // Update the URL hash when the modal is opened/closed
  useEffect(() => {
    if (isSsr) {
      return
    }

    const hash = location.hash

    const urlWithoutHash = location.href.replace(location.origin, '').replace(hash, '')

    if (state.type === 'CLOSED') {
      navigate(urlWithoutHash)
      return
    }

    if (hash !== editHash) {
      navigate(`${urlWithoutHash}${editHash}`)
    }
  }, [location.href, location.hash, location.origin, navigate, isSsr, state])

  // Close modal when back navigation is detected
  useEffect(() => {
    if (isSsr) {
      return
    }

    window.addEventListener('popstate', closeModal)
    return () => window.removeEventListener('popstate', closeModal)
  }, [isSsr])

  if (state.type === 'CLOSED') {
    return null
  }

  return (
    <Modal isOpen onEscapeKeydown={closeModal} onBackgroundClick={closeModal}>
      <Styled.ModalContainer>
        {state.type === 'OPEN' && (
          <ModalContent onCloseModal={closeModal} initialModalData={state.data} />
        )}
        {state.type === 'LOADING' && (
          <Styled.ModalSpinnerContainer>
            <Styled.CloseButton onClick={closeModal} aria-label="Close modal">
              <img alt="Close modal" src={closeModalIcon} />
            </Styled.CloseButton>
            <Spinner loading />
          </Styled.ModalSpinnerContainer>
        )}
        {state.type === 'ERROR' && (
          <Styled.ModalErrorContainer>
            <div>There was an error loading the product, please try again later.</div>
          </Styled.ModalErrorContainer>
        )}
      </Styled.ModalContainer>
    </Modal>
  )
}

export const ProductModal: React.VFC = () => {
  return (
    <Subscribe fallback={null} source$={latestModalState$}>
      <ProductModalReactive />
    </Subscribe>
  )
}
